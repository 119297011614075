import Page from "../ui/Page";


export default function NotFound () {
    return <Page title="404: NotFound">
        <div className="w-1/2 mx-auto text-center">
            <img src={process.env.PUBLIC_URL + '/img/NotFound.webp'} alt="Page Not Found"/>
            <span className="font-bold">Page not found.</span>
        </div>
    </Page>
}