import {Link, useNavigate} from "react-router-dom";
import {ReactNode} from "react";
import {Helmet} from "react-helmet";

let appTitle = "ReplyBot.io";
let defaultDescription = "Automate repetitive tasks straight out of your inbox.";

export default function Page({children, title, description} : {
    children: ReactNode,
    title?:string,
    description?:string,
}) {

    console.log(process.env)
    let calcTitle = title === undefined ? appTitle : title + " | " + appTitle;
    return <>
        <Helmet>
            <title>{calcTitle}</title>
            <meta name="title" content={calcTitle}/>
            <meta name="description" content={description === undefined? defaultDescription : description}/>
            <meta property="og:title" content={title === undefined ? appTitle : title}/>
            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://www.imdb.com/title/tt0117500/" />
            <meta property="og:image" content={process.env.REACT_APP_HOSTNAME + "/public/img/Social.webp"} />
        </Helmet>
        <div className="h-full">
            <div className="w-[100%] p-4 bg-[#F0F0F0] align-middle">
                <div className="inline">
                    <div className="inline p-2 font-extrabold text-3xl">
                        <Link to={"/"}>
                            <span className="text-[#004080]">Reply</span><span className="text-[#D32F2F]">Bot</span><span className="text-blue-700">.io</span><img alt="replybot logo" className="inline h-12 ml-1 -mt-1" src={process.env.PUBLIC_URL + '/logo192.png'} />
                        </Link>
                    </div>
                </div>
                <div className="inline float-right h-full">
                    <a href="https://app.replybot.io">
                        <span className="rounded px-2 py-1 mt-10 hover:bg-white cursor-pointer">Login</span>
                    </a>
                </div>
            </div>
            <div className="m-4 mt-0 max-w-[1024px] mx-auto block">
                {children}
            </div>
        </div>
    </>
}
