import Page from "../ui/Page";
import {Link} from "react-router-dom";

export default function Home() {
    return <Page>
        <div className="text-center">
            <div className="align-middle my-16 mx-auto inline-block">
                <img src={process.env.PUBLIC_URL + "/img/HomeRobot.webp"} alt="reply bot robot" className="h-64 inline"/>
                <div className="inline p-2 font-extrabold text-7xl margin-y-auto">
                    <span className="text-[#004080]">Reply</span><span className="text-[#D32F2F]">Bot</span><span className="text-blue-700">.io</span><img alt="replybot logo" className="inline h-16 ml-2 -mt-1" src={process.env.PUBLIC_URL + '/logo192.png'} />
                </div>
            </div>
            <h2 className="text-[#004080] text-4xl">Automate repetitive tasks straight <u className="text-[#D32F2F]">out of your inbox.</u></h2>
        </div>
        <div className="mt-4">
        </div>
    </Page>
}